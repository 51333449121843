import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import ContactPage from "./contact";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { ThemeProvider } from "./../util/theme";

function App(props) {

  return (
    <ThemeProvider>
      <Router>
        <>
          <Navbar
            color="default"
            logo="favicon1.png"
          />
          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/legal/:section" component={LegalPage} />

            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            bgColor="light"
            size="normal"
            bgImage=""
            bgImageOpacity={1}
            description="Mail Time"
            copyright={`Copyright © 2024 Mail Time - All rights reserved`}
            sticky={true}
          />
        </>
      </Router>
    </ThemeProvider>
  );
}

export default App;
