import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      // marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    // maxWidth: 300,
    // margin: "3em auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
    borderRadius: "20%",
    marginTop: '1em',
    // paddingRight: '1em',
    // transform: "scale(1.3)",
  },
  video: {
    height: "auto",
    maxWidth: "100%",
    borderRadius: "10%",
    marginTop: '1em',
    paddingRight: '1em',
    transform: "scale(1.1)",
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Direct Customer Reach",
      description:
        "Letterbox advertising ensures your message lands directly in the hands of local customers, increasing the likelihood of engagement and response.",
      image: "./direct-customer-reach.png",
    },
    {
      title: "Cost-Effective Marketing",
      description:
        "Compared to other advertising methods, letterbox distribution offers a cost-effective way to target specific neighborhoods and demographics, maximizing your advertising budget.",
      image: "./cost-effective-marketing.png",
    },
    {
      title: "Customizable Campaigns",
      description:
        "Tailor your advertising materials to suit your campaign needs—whether it's for a seasonal promotion, a new product launch, or a special event. Customization ensures relevance and impact. If you don't have a flyer or advertisement design yet, we can assist with designing and printing them!",
      image: "./customizable-campaigns.png",
    },
    {
      title: "High Visibility",
      description:
        "Your advertisements won't get lost in the digital noise. Physical mail has a tangible presence, making it more likely to be noticed and acted upon by recipients.",
      image: "./high-visibility.png",
    },
  ];

  const whyItems = [
    {
      title: "Localized Approach",
      description:
        "We specialize in localized advertising strategies, tailoring campaigns to effectively engage with your community and target audience.",
    },
    {
      title: "Reliable Delivery",
      description:
        "We guarantee timely and accurate delivery of your advertising materials, ensuring your message gets to your customers when it matters most.",
    },
    {
      title: "Flexible Scheduling",
      description:
        "We offer flexible distribution schedules to match your campaign timelines, helping you coordinate your advertising efforts seamlessly.",
    },
    {
      title: "Dedicated Support",
      description:
        "Our customer support team is always available to assist you with planning, executing, and optimizing your letterbox advertising campaigns.",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.itemsContainer}
        >
          {items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                {item.image ? <figure className={classes.figure}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className={classes.image}
                  />
                </figure> 
                : 
                <video src={item.video} alt={item.title} className={classes.video} autoPlay muted loop/>
                }
              </Grid>
            </Grid>
          ))}<br/><br/>
          <Typography variant="h4" gutterBottom={true} align="center">
            Why Choose Mail Time?
          </Typography>
          {whyItems.map((item, index) => (
            <Grid
              // className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={8}
              key={index}
            >
              <Grid item={true} xs={12} md={12}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              
            </Grid>
          ))}<br/><br/><br/>
          <Typography variant="h4" gutterBottom={true} align="center">
          Ready to reach local customers with letterbox advertising?
          </Typography><br/>
          <Typography variant="subtitle1">Contact Mail Time for a free personalized consultation. Our team is here to help you maximize your marketing impact. Let's make your campaigns effective and hassle-free with Mail Time's reliable delivery solutions.</Typography>
          <br/>
          <iframe src="https://forms.monday.com/forms/embed/74489edbf156eb17d6e75c5914048566?r=apse2" width="100%" height="1100" style={{border: '0', boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)", margin: 0}}></iframe>
         
          <br/><br/><br/><br/>
        </Container>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
