import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import LinkMui from "@material-ui/core/Link";
import { Link } from "./../util/router";
// import { useAuth } from "./../util/auth";
// import { useAuth0 } from '@auth0/auth0-react';
import { useDarkMode } from "./../util/theme";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 48,
    marginTop: '1em',
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
  link: {
    color: "inherit",
    lineHeight: 1,
    "&:not(:last-of-type)": {
      marginRight: "1.2rem",
    },
  },
  logoImg: {
    height: 39,
  },
}));

function Navbar(props) {
  const classes = useStyles();
  // const {
  //   isLoading,
  //   isAuthenticated,
  //   error,
  //   user,
  //   loginWithRedirect,
  //   logout,
  //   getAccessTokenSilently 
  // } = useAuth0();
  // const auth = useAuth();
  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);

  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  (async () => {
    // if (isAuthenticated && !auth.user) { //If user was just authenticated
    //   const accessToken = await getAccessTokenSilently();
            
    //   await auth.handleAuth({
    //     ...user,
    //     token: accessToken
    //   });
    // } 
  })();


  return (
    <Section bgColor={props.color} size="auto">
      <AppBar position="static" color="transparent" elevation={0}>
        <Container disableGutters={true}>
          <Toolbar className={classes.logo}>
            <LinkMui component={Link} to="/" className={classes.link}>
              {/* <video src={logo} alt="Logo" className={classes.logo} autoPlay muted loop/> */}
              
              <Typography variant="h4"><img className={classes.logoImg} src={props.logo} alt="Logo" style={{marginRight: '5px', marginTop: '2px'}}  /> Mail Time</Typography>
            </LinkMui>
            <div className={classes.spacer} />
          </Toolbar>
        </Container>
      </AppBar>
 
    </Section>
  );
}

export default Navbar;
